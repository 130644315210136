




















































































































































































































































































































.review {
  background-color: white;
  margin: 0;
  padding: 24px;
  min-height: 100vh;

  &__wrapper {
    margin: 0 auto;
    justify-content: center;
  }

  &__logo {
    width: 160px;
    margin: 0 auto 5px auto;
  }

  &__title {
    size: 13px;
    text-align: center;
    font-weight: 500;
    color: #1D293F;
    margin: 0 auto 5px auto;
  }

  &__table {
    size: 13px;
    font-weight: 400;
    color: #1D293F;
    width: 100%;

    & td {
      padding: 8px 12px;
      border: 1px solid #D5D9E1;
    }

    &-tr-bold {
      font-weight: 700;
    }
  }
}
